<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center h-screen"
  >
    <div class="d-flex align-items-center justify-content-center">
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
    </div>
    <div class="d-flex p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("notAutherized") }} 🔐</h2>
        <p class="mb-2">
          {{ $t("youDontHavePerm") }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
          >{{ $t("backToHome") }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

export default {
  components: {
    BLink,
    BButton,
    VuexyLogo,
  },
  data() {
    return {};
  },

  mounted() {},
  methods: {
    loginRoute() {
      return getHomeRouteForLoggedInUser();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.h-screen {
  height: 100vh;
}
</style>
